import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grow,
  Paper,
  Popper,
  Button,
  ClickAwayListener
} from '@material-ui/core'
import { Person as PersonIcon } from '@material-ui/icons'
import UserList from './UserList'

const useStyles = makeStyles(theme => ({
  paper: {
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palettePers.secondary
  },
  icon: {
    marginRigth: 10,
    color: theme.palettePers.text.icon
  },
  button: {
    color: theme.palettePers.text.secondary,
    minWidth: 200,
    width: '100%',
    marginTop: 7
  }
}))

const DropDown = ({ userName }) => {
  console.log('DropDown props:', userName)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorEl = React.useRef(null)

  function handleToggle() {
    setOpen(!open)
  }

  function handleClose(event) {
    if (anchorEl.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Button
        aria-haspopup="true"
        buttonRef={anchorEl}
        onClick={handleToggle}
        className={classes.button}
        aria-owns={open ? 'menu-list-grow' : undefined}
      >
        <PersonIcon className={classes.icon} />
        {userName}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        placement="bottom"
        transition
        className={classes.paper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="menu-list-grow">
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <UserList />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

DropDown.propTypes = {
  userName: PropTypes.string
}

DropDown.defaultProps = {
  userName: ''
}

export default DropDown
