import axios from 'axios'
import intl from 'react-intl-universal'

const event401 = new Event('error-401')

const validateError = err => {
  try {
    if (axios.isCancel(err)) {
      return `cancelled`
    }

    const errMessage = []

    console.log(JSON.stringify(err, null, 2))
    console.log(JSON.stringify(err.response, null, 2))
    console.log(JSON.stringify(err.response.data, null, 2))

    // eu verifico o err.message por que?
    // assim que a mudanca no back for realizada o vetor de resposta estará em err.response.data
    if (err && err.response.data.length > 0) {
      const responseErrors = err.response.data

      responseErrors.forEach(error => {
        console.log(error)
        errMessage.push(intl.get(`errors.${error}`))

        if (error === 'invalid_token') {
          window.dispatchEvent(event401)
        }
      })
    }

    return errMessage.length > 0
      ? errMessage
      : intl.get(`helperTexts.errorNotFound`)
  } catch (err) {
    console.error('catch: ', err)
    return intl.get(`helperTexts.errorNotFound`)
  }
}

export default validateError
