import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { loadItem, removeItem, saveItem } from './utils/localStorage'

const user = loadItem('userPartner')
const token = loadItem('tokenPartner')
const company = loadItem('companyPartner')
const partners = loadItem('partnersPartner')
const scope = loadItem('scopePartner')

const modelThemes = {
  type: 'light',
  data: {
    typography: {
      useNextVariants: true,
      fontFamily: "'Titillium Web', sans-serif"
    },
    paletteDefaultLight: {
      type: 'light',
      primary: '#001064',
      secondary: '#ffffff',
      tertiary: '#ffffff',
      fourth: '#f5f5f5',
      line: '#f5f5f5',
      text: {
        icon: '#d4a977',
        primary: '#ffffff',
        secondary: '#696969',
        tertiary: '#696969',
        fourth: '#696969'
      },
      graph: {
        text: '#000000',
        background: '#ffffff',
        axis: ['#001064', '#d4a977', '#228B22', '#DC7633  ', '#A52A2A']
      },
      maps: 'light'
    },
    paletteDefaultDark: {
      type: 'dark',
      primary: '#3B3A39',
      secondary: '#201F1E',
      tertiary: '#292827',
      fourth: '#201F1E',
      line: '#525252',
      text: {
        icon: '#ffffff',
        primary: '#EBF6F6',
        secondary: '#F6F6F6',
        tertiary: '#F6F6F6',
        fourth: '#F6F6F6'
      },
      graph: {
        text: '#000000',
        background: '#ffffff',
        axis: ['#001064', '#d4a977', '#228B22', '#DC7633  ', '#A52A2A']
      },
      maps: 'dark'
    },
    palettePersonalizated: {
      type: 'pers',
      primary: '#001064',
      secondary: '#ffffff',
      tertiary: '#ffffff',
      fourth: '#f5f5f5',
      line: '#f5f5f5',
      text: {
        icon: '#d4a977',
        primary: '#ffffff',
        secondary: '#696969',
        tertiary: '#696969',
        fourth: '#696969'
      },
      graph: {
        text: '#000000',
        background: '#ffffff',
        axis: ['#001064', '#d4a977', '#228B22', '#DC7633  ', '#A52A2A']
      },
      maps: 'light'
    },
    palette: {
      type: 'light',
      primary: '#001064',
      secondary: '#ffffff',
      tertiary: '#ffffff',
      fourth: '#f5f5f5',
      line: '#f5f5f5',
      text: {
        icon: '#d4a977',
        primary: '#ffffff',
        secondary: '#696969',
        tertiary: '#696969',
        fourth: '#696969'
      },
      graph: {
        text: '#000000',
        background: '#ffffff',
        axis: ['#001064', '#d4a977', '#228B22', '#DC7633  ', '#A52A2A']
      },
      maps: 'light'
    }
  }
}

const initialState = {
  user: user || {},
  token: token || '',
  theme: modelThemes,
  company: company || {},
  partners: partners || {},
  scope: scope || ''
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setIsSideMenuOpen':
      return {
        ...state,
        isSideMenuOpen:
          action.isOpen !== undefined ? action.isOpen : !state.isSideMenuOpen
      }
    case 'setUser':
      return {
        ...state,
        user: action.user
      }
    case 'setEntity':
      return {
        ...state,
        entity: action.entity
      }
    case 'setToken':
      return {
        ...state,
        token: action.token
      }
    case 'setCompany':
      return {
        ...state,
        company: action.company
      }
    case 'setCompanyMask':
      return {
        ...state,
        companyMask: action.companyMask
      }
    case 'setCountry':
      return {
        ...state,
        country: action.country
      }
    case 'setProvider':
      return {
        ...state,
        provider: action.provider
      }
    case 'setTimeZone':
      return {
        ...state,
        timezone: action.timezone
      }
    case 'setSystemRole':
      return {
        ...state,
        systemRole: action.systemRole
      }
    case 'setDefaultScreen':
      return {
        ...state,
        defaultScreen: action.defaultScreen
      }
    case 'setAccessRoles':
      return {
        ...state,
        accessRoles: action.accessRoles
      }
    case 'update':
      saveItem(action.user, 'userPartner')
      saveItem(action.company, 'companyPartner')
      saveItem(action.partners, 'partnersPartner')

      return {
        ...state,
        user: action.user,
        company: action.company,
        partners: action.partners
      }
    case 'login':
      saveItem(action.user, 'userPartner')
      saveItem(action.token, 'tokenPartner')
      saveItem(action.company, 'companyPartner')
      saveItem(action.partners, 'partnersPartner')
      saveItem(action.scope, 'scopePartner')

      return {
        ...state,
        user: action.user,
        token: action.token,
        company: action.company,
        partners: action.partners,
        scope: action.scope
      }

    case 'logout':
      removeItem('userPartner')
      removeItem('tokenPartner')
      removeItem('companyPartner')
      removeItem('partnersPartner')
      removeItem('scopePartner')

      return {
        ...state,
        user: {},
        token: '',
        company: {},
        partners: {},
        scope: ''
      }
    case 'setUserSettings':
      saveItem(action.res.user, 'userPartner')
      saveItem(action.res.entity, 'entityPartner')
      return {
        ...state,
        ...action.res
      }
    case 'setNewPassword':
      saveItem(action.res.user, 'userPartner')
      saveItem(action.res.entity, 'entityPartner')
      saveItem(action.res.token, 'tokenPartner')
      return {
        ...state,
        ...action.res
      }
    case 'newTheme':
      const newPalette = {
        type: action.newTheme.type,
        data: {
          ...state.theme.data,
          palette: action.newTheme
        }
      }
      saveItem(newPalette, 'themePartner')
      return {
        ...state,
        theme: newPalette
      }
    case 'setAlert':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          alerts: action.alerts
        }
      }
    default:
      throw new Error('Unexpected action')
  }
}

export const Context = React.createContext()

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState)
  return <Context.Provider value={context}>{children}</Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.any
}

export default Provider
