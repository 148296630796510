import { create } from 'jss'
import React, { useContext, useState, useEffect } from 'react'
import {
  createMuiTheme,
  ThemeProvider,
  createGenerateClassName,
  jssPreset
} from '@material-ui/core/styles'
import { JssProvider } from 'react-jss'
import { Context } from './Reducer'
import App from './App'

const jss = create(jssPreset())
const generateClassName = createGenerateClassName()

const ThemeState = () => {
  const [state] = useContext(Context) // isso talvez esteja errado? nao tem []
  const [theme, setTheme] = useState({})

  useEffect(() => {
    if (state.theme.data) {
      setTheme({
        // CONFIGURAÇÃO PERSONALIZADA
        typography: {
          ...state.theme.data.typography
        },
        palettePers: {
          ...state.theme.data.palette
        },
        // PALETA DEFAULT DO MATERIAL-UI
        palette: {
          action: {
            active: state.theme.data.palette.text.tertiary,
            disabled: state.theme.data.palette.text.tertiary
          },
          background: {
            paper: state.theme.data.palette.fourth
          },
          primary: {
            light: state.theme.data.palette.primary,
            main: state.theme.data.palette.primary,
            dark: state.theme.data.palette.primary,
            gold: state.theme.data.palette.primary,
            contrastText: state.theme.data.palette.primary
          },
          secondary: {
            light: state.theme.data.palette.secondary,
            main: state.theme.data.palette.secondary,
            dark: state.theme.data.palette.secondary,
            contrastText: state.theme.data.palette.secondary
          },
          tertiary: {
            light: state.theme.data.palette.tertiary,
            main: state.theme.data.palette.tertiary,
            dark: state.theme.data.palette.tertiary,
            contrastText: state.theme.data.palette.tertiary
          },
          fourth: {
            light: state.theme.data.palette.fourth,
            main: state.theme.data.palette.fourth,
            dark: state.theme.data.palette.fourth,
            contrastText: state.theme.data.palette.fourth
          },
          text: {
            primary: state.theme.data.palette.text.tertiary,
            secondary: state.theme.data.palette.text.tertiary,
            hint: state.theme.data.palette.text.tertiary
          }
        },
        overrides: {
          MuiPickersDay: {
            day: {
              color: state.theme.data.palette.text.tertiary
            },
            current: {
              color: state.theme.data.palette.text.tertiary
            },
            daySelected: {
              backgroundColor: state.theme.data.palette.primary,
              color: state.theme.data.palette.text.primary
            }
          },
          MuiPickersMonth: {
            monthSelected: {
              backgroundColor: state.theme.data.palette.primary,
              color: state.theme.data.palette.text.primary
            }
          }
        }
      })
    }
  }, [state.theme])

  const muiTheme = createMuiTheme(theme)

  return (
    <ThemeProvider theme={muiTheme}>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <App />
      </JssProvider>
    </ThemeProvider>
  )
}

export default ThemeState
