import validateError from '../utils/auth'
import { loadItem } from '../utils/localStorage'

const get = (axios, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }

      const token = loadItem('tokenPartner')

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/private/user/info`,
        { headers, cancelToken: cancelToken.token }
      )

      resolve(result.data)
    } catch (err) {
      console.error(err)

      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}

const getLogin = (axios, email, token, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        email
      }

      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/partnersUsers/valid`,
        { headers, cancelToken: cancelToken.token }
      )

      resolve(result.data)
    } catch (err) {
      console.error(err)
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}

const putForgotPassword = (axios, email, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }
      if (!email) {
        throw new Error('email required')
      }

      const body = { user: email }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/public/auth/forgotPassword`,
        body,
        { cancelToken: cancelToken.token }
      )

      resolve({})
    } catch (err) {
      console.error(err)
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}

const putNewPasswordEmail = (axios, password, token, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }
      if (!password) {
        throw new Error('Password required')
      }

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const body = { password }

      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/private/user/newPasswordEmail`,
        body,
        { headers, cancelToken: cancelToken.token }
      )

      resolve(result.data)
    } catch (err) {
      console.error(err)
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}

const putLogout = (axios, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }

      const token = loadItem('tokenPartner')

      const headers = {
        Authorization: `Bearer ${token}`
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/private/user/logout`,
        {},
        { headers, cancelToken: cancelToken.token }
      )

      resolve({})
    } catch (err) {
      console.error(err)
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}

const putUser = (axios, cancelToken, settings) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }

      const token = loadItem('tokenPartner')

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const body = { ...settings }

      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/private/user/settings`,
        body,
        { headers, cancelToken: cancelToken.token }
      )

      resolve(result.data)
    } catch (err) {
      console.error(err)
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}

const putTheme = (axios, cancelToken, theme) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }

      const token = loadItem('tokenPartner')

      const headers = {
        Authorization: `Bearer ${token}`
      }

      const body = { ...theme }

      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/private/user/theme`,
        body,
        { headers, cancelToken: cancelToken.token }
      )

      resolve(result.data)
    } catch (err) {
      console.error(err)
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}


export default {
  get,
  putLogout,
  getLogin,
  putForgotPassword,
  putNewPasswordEmail,
  putUser,
  putTheme
}
