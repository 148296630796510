import './styles'
import intl from 'react-intl-universal'
import React, { useState, useEffect, useContext } from 'react'
import { SnackbarProvider } from 'notistack'
import Router from './Router'
import { locales } from './utils/i18n'
import { Context } from './Reducer'

const App = () => {
  const [state] = useContext(Context)
  const [initDone, setInitDone] = useState(false)

  useEffect(() => {
    let currentLanguage = intl.getInitOptions()
    currentLanguage = currentLanguage.currentLocale
    let newLanguage = 'pt-BR'

    if (currentLanguage === state.user.language) {
      return
    }

    if (window.navigator.language && locales[window.navigator.language]) {
      newLanguage = window.navigator.language
    }

    if (state.user.language) {
      newLanguage = state.user.language
    }

    intl
      .init({
        locales,
        fallbackLocale: 'pt-BR',
        currentLocale: newLanguage
      })
      .then(() => {
        setInitDone(true)
      })
  }, [state.user.language])

  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transitionDuration={{ exit: 380, enter: 400 }}
    >
      <div className="App">{initDone && <Router />}</div>
    </SnackbarProvider>
  )
}

export default App
