import React, { useEffect, useMemo, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { List } from '@material-ui/core'
import { uniqueId } from 'lodash'
import MenuItem from './MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import ListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'

import {
  Menu as MenuIcon,
  Send as SendIcon,
  ExitToApp as ExitToAppIcon
} from '@material-ui/icons'
import { useLocation } from 'react-router-dom'
import { Context } from '../../../Reducer'

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    border: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: theme.palettePers.primary,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%'
  },
  drawerIcon: {
    color: theme.palettePers.text.icon,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  drawerIconRotate: {
    color: theme.palettePers.text.icon,
    transform: 'rotate(90deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  logoItem: {
    marginTop: 1
  },
  logo: {
    width: 80,
    marginLeft: 4,
    marginTop: -45,
    marginBottom: -50
  },
  divider: {
    margin: '0 10px',
    backgroundColor: theme.palettePers.text.icon,
    marginBottom: 20
  },
  drawerText: {
    color: theme.palettePers.text.primary
  },
  menuListApp: {
    backgroundColor: theme.palettePers.primary
  }
}))

function LeftMenuApp({ isOpen, onClose, items }) {
  const classes = useStyles()
  const location = useLocation()
  const [stateContext, dispatch] = useContext(Context)
  const [anchorEl, setAnchorEl] = useState(null)

  const logout = () => {
    dispatch({ type: 'logout' })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    handleClose()
  }, [location])

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 200)
  })

  const list = useMemo(() => {
    return items && items.map(item => (
      <MenuItem key={uniqueId()} isOpen={isOpen} onClose={onClose} {...item} />
    ))
  }, [items, isOpen])

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={event => setAnchorEl(event.currentTarget)}
        className={classNames(classes.menuButtonApp, classes.drawerIcon)}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.menuListApp
        }}
      >
        <List className={classes.drawer} onClose={onClose}>
          {list}
          <ListItem button className={classes.simple} onClick={logout}>
            <Tooltip title="Sair">
              <ListItemIcon className={classes.drawerIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primaryTypographyProps={{ className: classes.drawerText }}
              primary="Sair"
            />
          </ListItem>
        </List>
      </Menu>
    </>
  )
}

LeftMenuApp.propTypes = {
  isOpen: PropTypes.bool,
  itens: PropTypes.element,
  onClose: PropTypes.func
}

LeftMenuApp.defaultProps = {
  isOpen: false,
  itens: null,
  onClose: () => {}
}

export default LeftMenuApp
