import PropTypes from 'prop-types'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import withWidth from '@material-ui/core/withWidth'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {
  Menu as MenuIcon,
  Send as SendIcon,
  ExitToApp as ExitToAppIcon
} from '@material-ui/icons'

import CssBaseline from '@material-ui/core/CssBaseline'
import React, { useState, useEffect, useContext } from 'react'
import intl from 'react-intl-universal'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import DropDown from './DropDown'
import Company from './Company'
import { Context } from '../../Reducer'

const drawerWidthLeft = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh',
    width: '100vw'
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palettePers.secondary,
    color: theme.palettePers.text.secondary,
    marginRight: 0,
    borderBottom: `1px solid ${theme.palettePers.line}`,
    width: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  menuButton: {
    marginLeft: 5,
    marginRight: 5
  },
  menuButtonApp: {
    marginLeft: 5
  },
  drawerLeft: {
    width: drawerWidthLeft,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRight: `1px solid ${theme.palettePers.line}`
  },
  drawerOpenLeft: {
    width: drawerWidthLeft,
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRight: `1px solid ${theme.palettePers.line}`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerCloseLeft: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    borderRight: `1px solid ${theme.palettePers.line}`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1) - 10,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1) - 30,
    marginTop: 49,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0,
    overflowY: 'hidden',
    backgroundColor: theme.palettePers.fourth
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  contentShiftRight: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  logo: {
    minWidth: '80px',
    width: 80,
    marginTop: -45,
    marginBottom: -50
  },
  header: {
    borderLeft: `1px solid ${theme.palettePers.line}`,
    paddingLeft: 10,
    marginTop: 3,
    marginBottom: 3,
    marginLeft: 101,
    color: theme.palettePers.text.icon,
    maxWidth: '100%'
  },
  drawerIcon: {
    color: theme.palettePers.text.icon,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  drawerIconRotate: {
    color: theme.palettePers.text.icon,
    transform: 'rotate(90deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  mainDiv: {
    height: '100%'
  },
  headerOpenRight: {
    width: `calc(100vw - 650px)`
  },
  headerCloseRight: {
    width: `calc(100vw - 250px)`
  },
  headerFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1
  },
  headerTitle: {
    flexGrow: 1,
    textAlign: 'left'
  },
  headerTitleApp: {
    flexGrow: 1,
    textAlign: 'center'
  },
  menuListApp: {
    backgroundColor: theme.palettePers.primary
  }
}))

const Shared = ({ logoHeader, header, menuLeft, children, width }, props) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const [stateContext, dispatch] = useContext(Context)
  const [openLeft, setOpenLeft] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setAnchorEl(null)
    setOpenLeft(false)
  }, [width])

  const handleDrawerOpenLeft = event => {
    setAnchorEl(event.currentTarget)
    setOpenLeft(!openLeft)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenLeft(!openLeft)
  }

  const logout = () => {
    dispatch({ type: 'logout' })
  }

  return (
    <>
      <div className={classes.root}>
        <Hidden xsDown>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar disableGutters variant="dense">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={handleDrawerOpenLeft}
                className={classNames(classes.menuButton, {
                  [classes.drawerIconRotate]: openLeft,
                  [classes.drawerIcon]: !openLeft
                })}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.logo}>
                <img src={logoHeader} alt="Logo" />
              </div>
              <div
                className={classNames(classes.header, classes.headerCloseRight)}
              >
                <div className={classes.headerFlex}>
                  <div className={classes.headerTitle}>{header}</div>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={logout}
                    className={classes.menuButtonApp}
                  >
                    <ExitToAppIcon />
                  </IconButton>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classNames(classes.drawerLeft, {
              [classes.drawerOpenLeft]: openLeft,
              [classes.drawerCloseLeft]: !openLeft
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpenLeft]: openLeft,
                [classes.drawerCloseLeft]: !openLeft
              })
            }}
            variant="permanent"
            anchor="left"
            open={openLeft}
            transitionDuration={400}
          >
            {menuLeft}
          </Drawer>
        </Hidden>
        <Hidden smUp>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar disableGutters>
              <div className={classes.logo}>
                <img src={logoHeader} alt="Logo" />
              </div>
              <div className={classes.headerFlex}>
                <div className={classes.headerTitleApp}>{header}</div>
              </div>
              {menuLeft}
            </Toolbar>
          </AppBar>
        </Hidden>
        <main
          className={classNames(classes.content, {
            [classes.contentShiftLeft]: openLeft
          })}
        >
          <div className={classes.mainDiv}>{children}</div>
        </main>
      </div>
    </>
  )
}

Shared.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  logoHeader: PropTypes.node,
  menuLeft: PropTypes.node
}

Shared.defaultProps = {
  children: PropTypes.any,
  header: PropTypes.any,
  logoHeader: PropTypes.any,
  menuLeft: PropTypes.any
}

export default withWidth()(Shared)
