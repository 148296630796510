import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Reducer from './Reducer'
import * as serviceWorker from './registerServiceWorker'
import ThemeState from './ThemeState'
import Button from '@material-ui/core/Button'

const onUpdate = param => {
  const event = new Event('newContentAvailable')
  window.dispatchEvent(event)
}

class NewApp extends Component {
  render() {
    return <ReloadModal />
  }
}

class ReloadModal extends Component {
  state = {
    show: false
  }
  componentDidMount() {
    // Handle global event.
    window.addEventListener('newContentAvailable', () => {
      this.setState({
        show: true
      })
    })
  }
  onClick = () => {
    // Reload when modal click.
    window.location.reload(window.location.href)
  }
  render() {
    if (!this.state.show) {
      return <ThemeState />
    }
    // <Modal> is common fixed component.
    return (
      <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
        <img src="/img/logoHeader.svg" />
        <h3>Uma nova versão está disponível!!!</h3>
        <h3>Por favor, atualize</h3>

        <Button variant="contained" color="primary" onClick={this.onClick}>
          Atualizar
        </Button>
      </div>
    )
  }
}

ReactDOM.render(
  <Reducer>
    <NewApp />
  </Reducer>,
  document.getElementById('root')
)

serviceWorker.register({ onUpdate: onUpdate })
