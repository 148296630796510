import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Context } from '../Reducer'
import Loading from '../components/loading'

const useStyles = makeStyles(theme => ({
  loadContainer: {
    width: '100%',
    textAlign: 'center',
    height: '100vh',
    fontFamily: theme.palettePers.typography,
    padding: 30
  },
  loading: {
    margin: '0px auto',
    width: '20%',
    marginTop: '10%',
    opacity: '0.2'
  }
}))

const LoadingComponent = ({ isLoading, error, pastDelay, timedOut }) => {
  const classes = useStyles()
  const [stateContext] = useContext(Context)

  if (isLoading && pastDelay) {
    return (
      <div className={classes.loading}>
        <Loading
          theme={
            stateContext && stateContext.theme.type
              ? stateContext.theme.type
              : 'light'
          }
        />
      </div>
    )
  }
  if (timedOut) {
    return <div className={classes.loadContainer} />
  }
  if (error) {
    return <div className={classes.loadContainer} />
  }
  return null
}

LoadingComponent.propTypes = {
  error: PropTypes.any,
  isLoading: PropTypes.any,
  pastDelay: PropTypes.any,
  timedOut: PropTypes.any
}

export default LoadingComponent
