import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Context } from '../../../Reducer'
import CompanySuggest from './CompanySuggest'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  formControl: {
    color: theme.palettePers.text.secondary,
    width: '100%'
  },
  div: {
    height: 40,
    marginBottom: 5,
    marginTop: 5,
    minWidth: 400
  },
  typography: {
    color: theme.palettePers.text.secondary,
    width: '100%',
    marginTop: '5%'
  }
}))

const CompanyName = ({ companyValue, handleCompanyChange }) => {
  const classes = useStyles()
  const [state] = useContext(Context)
  const location = useLocation()

  if (
    state.systemRole.name !== 'lyra' &&
    state.systemRole.name !== 'commercial' &&
    state.systemRole.name !== 'provider'
  ) {
    return (
      <Typography variant="h6" noWrap className={classes.typography}>
        {state.entity.name}
      </Typography>
    )
  }
  if (
    location &&
    (location.pathname === '/' ||
      location.pathname === '/pdp' ||
      location.pathname === '/perimeter')
  ) {
    return (
      <CompanySuggest
        hasSuggested
        value={companyValue}
        minToMakeRequest={0}
        defaultName="companyName"
        suggestionUrl="company_filter"
        onUpdate={handleCompanyChange}
        behavior="text"
        columnIdName="companyName"
        isSuggested
        type="text"
        filter={{
          behavior: 'text',
          columnIdName: 'column',
          defaultName: 'companyName',
          hasSuggested: true,
          isSuggested: false,
          minToMakeRequest: 0,
          suggestionUrl: 'company_filter',
          type: 'text',
          value: companyValue
        }}
        filterParameters=""
        className={classes.formControl}
      />
    )
  }
  return <div className={classes.div} />
}

CompanyName.propTypes = {
  companyValue: PropTypes.number,
  handleCompanyChange: PropTypes.func,
  location: PropTypes.object
}

CompanyName.defaultProps = {
  companyValue: 0,
  handleCompanyChange: () => {},
  location: null
}

export default CompanyName
