import axios from 'axios'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core'
import React, { useRef, useState, useEffect, useContext } from 'react'
import {
  ExitToApp as ExitToAppIcon,
  PersonOutline as PersonOutlineIcon,
  InvertColors
} from '@material-ui/icons'
import API from '../../../API'
import { Context } from '../../../Reducer'

const { CancelToken } = axios

const useStyles = makeStyles(theme => ({
  dropDownMenuItem: {
    fontSize: 14
  },

  logout: {
    textTransform: 'capitalize'
  },

  dropDownLink: {
    color: '#2b3c7a',
    textDecoration: 'none',
    '& svg': {
      marginBottom: -3
    }
  },
  dropDownLinkButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    color: theme.palettePers.text.icon
  },
  itemPrimary: {
    color: theme.palettePers.text.secondary
  },
  itemSecondary: {
    color: theme.palettePers.text.secondary
  },
  divider: {
    backgroundColor: theme.palettePers.line
  }
}))

const defaultRequest = {
  data: {},
  error: false,
  errorMessage: '',
  isFetching: false,
  isCancelled: false
}

const UserList = ({ handleLogout }) => {
  console.log('UserList props: ', handleLogout)
  const classes = useStyles()
  const [, dispatch] = useContext(Context)
  const axiosToken = useRef(CancelToken.source())
  const [request, setRequest] = useState(defaultRequest)
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    return () => {
      axiosToken.current.cancel(`Cancelled on unmount`)
    }
  }, [])

  const logout = () => {
    if (request.isFetching) {
      return
    }

    setRequest({
      ...defaultRequest,
      isFetching: true
    })

    API.User.putLogout(axios, axiosToken)
      .then(data => {
        setRequest({
          ...defaultRequest,
          data
        })

        dispatch({ type: 'logout' })
      })
      .catch(err => {
        if (err !== `cancelled`) {
          setRequest({
            ...defaultRequest,
            error: true,
            errorMessage: err
          })

          dispatch({ type: 'logout' })
        }
      })
  }

  if (redirect) {
    if (redirect !== window.location.pathname) {
      return <Redirect to={redirect} />
    }
  }

  return (
    <>
      <List component="nav">
        <ListItem button onClick={() => setRedirect('/profile')}>
          <ListItemIcon>
            <PersonOutlineIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={intl.get(`words.profile`)}
            primaryTypographyProps={{ className: classes.itemPrimary }}
          />
        </ListItem>
      </List>
      <Divider className={classes.divider} />
      <List component="nav">
        <ListItem button onClick={() => setRedirect('/theme')}>
          <ListItemIcon>
            <InvertColors className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={intl.get(`words.themes`)}
            primaryTypographyProps={{ className: classes.itemPrimary }}
          />
        </ListItem>
      </List>
      <Divider className={classes.divider} />
      <List component="nav">
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={intl.get(`words.logout`)}
            primaryTypographyProps={{ className: classes.itemPrimary }}
          />
        </ListItem>
      </List>
    </>
  )
}

UserList.propTypes = {}

UserList.defaultProps = {}

export default UserList
