import validateError from '../utils/auth'

const getOrigin = (scope, axios, query, email, token, cancelToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!axios) {
        throw new Error('Axios required')
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        email
      }

      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/${scope}/inventory${query}`,
        { headers, cancelToken: cancelToken.token }
      )

      resolve(result.data)
    } catch (err) {
      console.error(err)
      const errMessage = await validateError(err)

      reject(errMessage)
    }
  })
}

export default {
  getOrigin
}
