import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { List } from '@material-ui/core'
import { uniqueId } from 'lodash'
import MenuItem from './MenuItem'

const useStyles = makeStyles(theme => ({
  drawer: {
    marginTop: '49px',
    flexShrink: 0,
    border: 'none',
    whiteSpace: 'nowrap',
    width: '100%',
    backgroundColor: theme.palettePers.primary,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%',
    paddingTop: 20
  },
  drawerOpen: {
    /* width: "100%", */
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
    /*  backgroundColor: theme.palettePers.primary, */
    /* overflowX: "hidden" */
  },
  drawerClose: {
    /* overflowX: "hidden", */
    width: theme.spacing(7) + 1
    /*  transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }), */
    /* overflowX: "hidden" */
  },
  drawerIcon: {
    color: theme.palettePers.text.icon,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  drawerIconRotate: {
    color: theme.palettePers.text.icon,
    transform: 'rotate(90deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  logoItem: {
    marginTop: 1
  },
  logo: {
    width: 80,
    marginLeft: 4,
    marginTop: -45,
    marginBottom: -50
  },
  divider: {
    margin: '0 10px',
    backgroundColor: theme.palettePers.text.icon,
    marginBottom: 20
  }
}))

function LeftMenu({ isOpen, onClose, items }) {
  console.log('LeftMenu props: ', isOpen, onClose, items)
  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 200)
  })

  const list = useMemo(() => {
    return items.map(item => (
      <MenuItem key={uniqueId()} isOpen={isOpen} onClose={onClose} {...item} />
    ))
  }, [items, isOpen])

  return (
    <List className={classNames(classes.drawer, classes.drawerOpen)}>
      {list}
    </List>
  )
}

LeftMenu.propTypes = {
  isOpen: PropTypes.bool,
  items: PropTypes.array,
  onClose: PropTypes.func
}

LeftMenu.defaultProps = {
  isOpen: false,
  items: null,
  onClose: () => {}
}

export default LeftMenu
