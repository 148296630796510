import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'

const useStyles = makeStyles(() => ({
  badge: {
    backgroundColor: 'red',
    color: '#fff'
  }
}))

const IconBadge = ({ contentValue, maxValue, childrenIcon }) => {
  console.log('BadgeVisibility props: ', contentValue, maxValue, childrenIcon)
  const classes = useStyles()
  return (
    <Badge
      badgeContent={contentValue}
      color="secondary"
      max={maxValue}
      classes={{ badge: classes.badge }}
    >
      {childrenIcon}
    </Badge>
  )
}

IconBadge.propTypes = {
  childrenIcon: PropTypes.any,
  contentValue: PropTypes.any,
  maxValue: PropTypes.any
}

export default IconBadge
