import intl from 'react-intl-universal'
import { useSnackbar } from 'notistack'
import React, { useState, useContext } from 'react'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter
} from 'react-router-dom'
import Loadable from 'react-loadable'
import {
  GroupWork as GroupIcon,
  Home as HomeIcon,
  List as ListIcon,
  Alarm as AlarmIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
  ShowChart as ShowChartIcon,
  MapOutlined as MapOutlinedIcon,
  Settings as SettingsIcon,
  SettingsInputAntenna as SettingsInputAntennaIcon,
  Assessment as AssessmentIcon,
  Archive as ArchiveIcon,
  BarChart as BarChartIcon,
  Warning as WarningIcon,
  Tv as TvIcon,
  CompareArrows as CompareArrowsIcon,
  Call as CallIcon,
  SimCard as SimCardIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Hidden } from '@material-ui/core'
import { Context } from './Reducer'
import useEventListener from './hooks/useEventListener'
import LoadingComponent from './utils/LoadingComponent'
import RoutesURL from './utils/routesURL'
import LeftMenu from './components/LeftMenu/Desktop'
import LeftMenuApp from './components/LeftMenu/Mobile'

import IconBadge from './components/LeftMenu/Desktop/IconBadge'
import Shared from './components/Shared'

const drawerWidthLeft = 240
const drawerWidthRight = 350

const AsyncLogin = Loadable({
  loader: () => import('./screens/public/Login'),
  loading: LoadingComponent,
  delay: 500,
  timeout: 3000
})
const AsyncDashboard = Loadable({
  loader: () => import('./screens/private/Dashboard'),
  loading: LoadingComponent,
  delay: 500,
  timeout: 3000
})
const AsyncSimCards = Loadable({
  loader: () => import('./screens/private/SimCards'),
  loading: LoadingComponent,
  delay: 500,
  timeout: 3000
})

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100vh',
    width: '100vw'
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palettePers.secondary,
    color: theme.palettePers.text.icon,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarRight: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palettePers.secondary,
    color: theme.palettePers.text.icon,
    marginRight: drawerWidthRight,
    width: `calc(100% - ${drawerWidthRight}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    width: `calc(100% - ${drawerWidthLeft}px)`,
    marginLeft: drawerWidthLeft,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 5,
    marginRight: 5
  },
  hide: {
    display: 'none'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  drawerLeft: {
    width: drawerWidthLeft,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  drawerOpenLeft: {
    width: drawerWidthLeft,
    overflowX: 'hidden',
    overflowY: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerCloseLeft: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  },
  drawerPaperLeft: {
    width: drawerWidthLeft
  },
  drawerHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  drawerRight: {
    width: drawerWidthRight,
    flexShrink: 0,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  drawerPaperRight: {
    width: drawerWidthRight,
    overflowX: 'hidden',
    overflowY: 'hidden'
  },
  drawerHeaderRight: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1) - 10,
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1) - 30,
    marginTop: 49,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0,
    marginRight: -drawerWidthRight,
    minHeight: '100%',
    overflowY: 'auto'
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  contentShiftRight: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  logo: {
    width: 80,
    marginTop: -45,
    marginBottom: -50
  },
  drawerIcon: {
    color: theme.palettePers.text.icon,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  drawerIconRotate: {
    color: theme.palettePers.text.icon,
    transform: 'rotate(90deg)',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  end: {
    textAlign: 'end',
    display: 'inline-flex',
    marginRight: 0
  },
  pageTitle: {
    marginTop: 7,
    color: theme.palettePers.text.secondary
  }
}))

const Router = () => {
  const classes = useStyles()
  const [stateContext, dispatch] = useContext(Context)
  const [openLeft, setOpenLeft] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  // Logout user and show a snackbar
  const logout = () => {
    if (stateContext.token) {
      dispatch({ type: 'logout' })
      enqueueSnackbar(intl.get('errors.invalid_token'), { variant: 'error' })
    }
  }

  // Detect error event
  useEventListener('error-401', logout)

  const routes = [
    {
      text: intl.get(`pages.dashboard`),
      icon: HomeIcon,
      path: '/',
      isVisible: true,
      main: props => {
        return (
          <AsyncDashboard
            {...props}
            companyName={stateContext.company.name}
            partnerName={stateContext.partners.name}
            scope={stateContext.scope}
          />
        )
      }
    },
    {
      text: intl.get(`pages.simCards`),
      icon: SimCardIcon,
      path: '/simcards',
      isVisible: true,
      main: props => {
        return <AsyncSimCards {...props} scope={stateContext.scope} />
      }
    },
    {
      path: '*',
      text: '',
      icon: HomeIcon,
      isVisible: false,
      main: () => {
        return <Redirect to={'/'} />
      }
    }
  ]

  const RenderRoutes = withRouter(props => {
    const { routes } = props

    const RouteItem = (route, index) => {
      return (
        <Route
          key={`${index}`}
          path={route.path}
          exact
          render={props => {
            return <route.main {...props} />
          }}
        />
      )
    }

    return (
      <Switch>
        {routes &&
          routes.map((route, index) => {
            return route.isNested
              ? route.items.map((route, index) => {
                  return RouteItem(route, index)
                })
              : RouteItem(route, index)
          })}
      </Switch>
    )
  })

  const RenderHeader = withRouter(() => {
    const RouteItem = (route, index) => {
      return (
        <Route
          key={`${index}`}
          path={route.path}
          exact
          render={() => {
            return (
              <Typography variant="h6" className={classes.pageTitle} noWrap>
                {route.text}
              </Typography>
            )
          }}
        />
      )
    }

    return (
      <Switch>
        {routes.map((route, index) => {
          return route.isNested
            ? route.items.map((route, index) => {
                return RouteItem(route, index)
              })
            : RouteItem(route, index)
        })}
      </Switch>
    )
  })

  const RenderMenuLeft = withRouter(() => {
    return (
      <>
        <Hidden xsDown>
          <LeftMenu
            items={routes}
            isOpen={openLeft}
            toggleOpen={() => setOpenLeft(!openLeft)}
          />
        </Hidden>
        <Hidden smUp>
          <LeftMenuApp
            items={routes}
            isOpen={openLeft}
            toggleOpen={() => setOpenLeft(!openLeft)}
          />
        </Hidden>
      </>
    )
  })

  const RouterShared = withRouter(props => {
    const { logoHeader, header, menuLeft, main } = props
    return (
      <Shared
        logoHeader={logoHeader}
        header={header}
        menuLeft={menuLeft}
        main={main}
        {...props}
      />
    )
  })

  return (
    <BrowserRouter>
      {stateContext.token ? (
        <RouterShared
          logoHeader={
            stateContext.theme.type === 'light'
              ? '/img/logoHeader.svg'
              : '/img/logoHeaderWhite.svg'
          }
          header={<RenderHeader />}
          menuLeft={<RenderMenuLeft />}
        >
          <RenderRoutes routes={routes} />
        </RouterShared>
      ) : (
        <Switch>
          <Route key="login" exact path="/login" component={AsyncLogin} />
          <Route key="all" path="*" component={AsyncLogin} />
        </Switch>
      )}
    </BrowserRouter>
  )
}

export default Router
