import intl from 'react-intl-universal'
import esJson from '../locales/es.json'
import frJson from '../locales/fr.json'
import ptBrJson from '../locales/pt-BR.json'
import { loadItem } from './localStorage'

const user = loadItem('userPartner')

const suportedLocales = ['es', 'fr', 'pt-BR']

const locales = {
  es: esJson,
  fr: frJson,
  'pt-BR': ptBrJson
}

const loadLocales = () => {
  let currentLocale

  if (user && user.language) {
    currentLocale = user.language
  } else {
    currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    })

    if (!suportedLocales.find(language => language === currentLocale)) {
      currentLocale = 'pt-BR'
    }
  }

  return currentLocale
}

const updateLocales = language => {
  let currentLocale = intl.getInitOptions()
  currentLocale = currentLocale.currentLocale

  if (!language) {
    currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    })
  }

  if (suportedLocales.find(value => value === language)) {
    currentLocale = language
  }

  return currentLocale
}

export { locales, loadLocales, updateLocales, suportedLocales }
