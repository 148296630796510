import _ from 'lodash'
import PropTypes from 'prop-types'
import axios from 'axios'
import intl from 'react-intl-universal'
import Autosuggest from 'react-autosuggest'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Paper, MenuItem, TextField } from '@material-ui/core'
import API from '../../../API'
import { Context } from '../../../Reducer'

const { CancelToken } = axios

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    border: `1px solid ${theme.palettePers.text.secondary}`,
    color: theme.palettePers.text.secondary,
    fontSize: '0.8em',
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    overflow: 'hidden',
    borderRadius: 4,
    marginBottom: 5,
    marginTop: 5,
    minWidth: 400
  },
  container: {
    position: 'relative',
    margin: 0
  },
  suggestionsContainerOpen: {
    zIndex: 1,
    marginTop: 5,
    maxHeight: 400,
    borderRadius: 5,
    overflowY: 'auto',
    position: 'absolute',
    border: `1px solid ${theme.palettePers.line}`,
    backgroundColor: theme.palettePers.secondary,
    color: theme.palettePers.secondary
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  div: {
    paddingRight: '5%'
  },
  menuItem: {
    color: theme.palettePers.text.secondary
  }
}))

const defaultRequest = {
  suggestionsTotal: [],
  isFetching: false,
  error: false,
  errorMessage: ''
}

const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const CompanySuggest = ({
  defaultName,
  hasSuggested,
  minToMakeRequest,
  suggestionUrl,
  onUpdate,
  filter,
  filterParameters,
  verifyValue
}) => {
  const classes = useStyles()
  const firstLoad = useRef(true)
  const prevValue = usePrevious(filter.value)
  const axiosToken = useRef(CancelToken.source())
  const [state, setState] = useState({ value: '', ...filter })
  const [request, setRequest] = useState(defaultRequest)
  const [stateContext] = useContext(Context)

  const [value, setValue] = useState(filter.value)
  const [suggest, setSuggest] = useState([])

  useEffect(() => {
    return () => {
      axiosToken.current.cancel(`Cancelled on unmount`)
    }
  }, [])

  useEffect(() => {
    if (!_.isEqual(prevValue, filter.value) && filter.value !== value) {
      setValue(filter.value)
    }
  })

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }
    validateValue()
  }, [state])

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }
    validateValue()
  }, [suggest])

  const validateValue = () => {
    const stateExport = {
      ...state
    }

    const foundElement = suggest.filter(
      suggest =>
        suggest.label.toString().toLowerCase() ===
        stateExport.value.toString().toLowerCase()
    )

    if (foundElement && foundElement.length === 1) {
      stateExport.isSuggested = true
      stateExport.id = foundElement[0].id
    } else {
      stateExport.isSuggested = false
    }

    onUpdate(stateExport)
  }

  useEffect(() => {
    if (firstLoad.current === true) {
      firstLoad.current = false
      return
    }
    setSuggest(getMatchingLanguages(state.value))
  }, [request])

  const getMatchingLanguages = value => {
    const escapedValue = escapeRegexCharacters(
      value
        .toString()
        .toLowerCase()
        .trim()
    )

    if (escapedValue === '') {
      return []
    }

    const regex = new RegExp(`^${escapedValue}`, 'i')

    return request.suggestionsTotal.filter(suggest => regex.test(suggest.label))
  }

  const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const getSuggestionValue = suggestion => {
    validateValue()
    return suggestion.label.toString()
  }

  const renderSuggestion = (suggestion, { isHighlighted }) => {
    return (
      <MenuItem
        selected={isHighlighted}
        component="div"
        classes={{
          root: classes.menuItem
        }}
      >
        <span>{suggestion.label}</span>
      </MenuItem>
    )
  }

  const loadSuggestions = value => {
    const newState = filterParameters
    if (newState[defaultName]) {
      delete newState[defaultName]
    }

    const filtersSelected = []
    Object.keys(newState).map(item => {
      return filtersSelected.push(verifyValue(item))
    })

    const filtersCleaned = filtersSelected.filter(item => {
      return item !== null
    })

    const filtersReady = {}
    filtersCleaned.map(item => {
      if (Array.isArray(item)) {
        item.map(sub => {
          filtersReady[sub.defaultColumn] = sub.value
          return filtersReady[sub.defaultColumn]
        })
      } else {
        filtersReady[item.defaultColumn] = item.value
        return filtersReady[item.defaultColumn]
      }
      return null
    })

    // Cancela o request
    if (request.isFetching === true) {
      return
    }

    setRequest({
      ...request,
      isFetching: true
    })

    // /API DEFINITIVA
    API.Filters.getSuggestion(
      axios,
      axiosToken.current.token,
      defaultName,
      value,
      suggestionUrl,
      filtersReady
    )
      .then(res => {
        setRequest({
          ...request,
          suggestionsTotal: res.data,
          isFetching: false
        })
      })
      .catch(err => {
        if (err !== `cancelled`) {
          setRequest({
            ...request,
            isFetching: false,
            error: true,
            errMessage: err
          })
        }
      })
  }

  const handleChange = (event, { newValue }) => {
    setState({ ...state, value: newValue })
    setValue(newValue)
  }

  const renderInputComponent = inputProps => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps
    return (
      <TextField
        fullWidth
        autoFocus={value.length > 0 && !stateContext.companyMask.id}
        InputProps={{
          inputRef: node => {
            ref(node)
            inputRef(node)
          },
          value,
          classes: {
            root: classes.root
          },
          disableUnderline: true
        }}
        {...other}
      />
    )
  }

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (
      hasSuggested === true &&
      value.length > minToMakeRequest &&
      request.isFetching === false
    ) {
      loadSuggestions(value)
    } else if (
      hasSuggested === true &&
      request.isFetching === false &&
      request.suggestionsTotal.length > 0
    ) {
      loadSuggestions(value)
    }
  }

  const handleSuggestionsClearRequested = () => {}

  const autosuggestProps = {
    renderInputComponent,
    suggestions: suggest,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion
  }

  return (
    <div className={classes.div}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes: {
            root: classes.root
          },
          placeholder: intl.get(`titles.${defaultName}`),
          value,
          onChange: handleChange,
          column: defaultName
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  )
}

CompanySuggest.propTypes = {
  defaultName: PropTypes.string,
  filter: PropTypes.object,
  filterParameters: PropTypes.array,
  hasSuggested: PropTypes.bool,
  minToMakeRequest: PropTypes.number,
  onUpdate: PropTypes.func,
  suggestionUrl: PropTypes.string,
  verifyValue: PropTypes.func
}

CompanySuggest.defaultProps = {
  defaultName: '',
  filter: {},
  filterParameters: [],
  hasSuggested: false,
  minToMakeRequest: 0,
  onUpdate: () => {},
  suggestionUrl: '',
  verifyValue: () => {}
}

export default CompanySuggest
