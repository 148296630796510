import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { NavLink as Option } from 'react-router-dom'

import {
  List,
  Tooltip,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons'
import { Context } from '../../../Reducer'

const useStyles = makeStyles(theme => ({
  drawerIcon: {
    color: theme.palettePers.text.icon,
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    })
  },
  expandIcon: {
    color: theme.palettePers.text.icon
  },
  drawerText: {
    color: theme.palettePers.text.primary
  },
  nested: {
    /* paddingLeft: theme.spacing(4), */
    paddingTop: 4,
    paddingBottom: 4
  },
  simple: {
    paddingTop: 4,
    paddingBottom: 4
  },
  active: {}
}))

const Item = ({ icon, text, path, nested, isVisible }) => {
  const Icon = icon
  const classes = useStyles()
  const [stateContext] = useContext(Context)

  if (!isVisible) {
    return <div />
  }

  return (
    <Option
      to={path}
      style={{
        textDecoration: 'none',
        color: stateContext.theme.data.palette.text.primary
      }}
      activeStyle={{
        color: stateContext.theme.data.palette.text.primary
      }}
      active={(window.location === path).toString()}
    >
      {/* TODO: mexi nisso aqui, e o erro sumiu, só não sei se fiz certo */}
      <ListItem button className={nested ? classes.nested : classes.simple}>
        <Tooltip title={text}>
          <ListItemIcon className={classes.drawerIcon}>
            <Icon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primaryTypographyProps={{ className: classes.drawerText }}
          primary={text}
        />
      </ListItem>
    </Option>
  )
}

Item.propTypes = {
  icon: PropTypes.element,
  isVisible: PropTypes.bool,
  nested: PropTypes.element,
  path: PropTypes.string,
  text: PropTypes.string
}

Item.defaultProps = {
  icon: null,
  isVisible: false,
  nested: null,
  path: '',
  text: ''
}

const NestedItem = ({ icon, text, itens, onClose }) => {
  const Icon = icon
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(false)

  if (!itens || itens.length === 0) {
    console.error('inform the submenus')

    return (
      <ListItem button>
        <ListItemText
          primaryTypographyProps={{ className: classes.drawerText }}
          inset
          primary="Error"
        />
      </ListItem>
    )
  }

  return (
    <>
      <ListItem button onClick={() => setIsExpanded(!isExpanded)}>
        <Tooltip title={text}>
          <ListItemIcon className={classes.drawerIcon}>
            <Icon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primaryTypographyProps={{ className: classes.drawerText }}
          primary={text}
        />
        {isExpanded ? (
          <ExpandLessIcon className={classes.expandIcon} />
        ) : (
          <ExpandMoreIcon className={classes.expandIcon} />
        )}
      </ListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {itens.map(item => (
            <Item
              key={item.text}
              {...item}
              onClose={onClose}
              nested={item.nested}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

NestedItem.propTypes = {
  icon: PropTypes.element,
  itens: PropTypes.element,
  onClose: PropTypes.func,
  text: PropTypes.string
}

NestedItem.defaultProps = {
  icon: PropTypes.any,
  itens: null,
  onClose: () => {},
  text: ''
}

const MenuItem = ({
  isNested,
  isVisible,
  icon,
  text,
  path,
  nested,
  itens,
  onClose
}) => {
  
  if (!isVisible) {
    return <div />
  }

  if (!isNested) {
    return (
      <Item
        isVisible={isVisible}
        icon={icon}
        text={text}
        path={path}
        nested={nested}
      />
    )
  }

  return <NestedItem icon={icon} text={text} itens={itens} onClose={onClose} />
}

MenuItem.propTypes = {
  isNested: PropTypes.bool,
  isVisible: PropTypes.bool,
  icon: PropTypes.any,
  text: PropTypes.any,
  path: PropTypes.any,
  nested: PropTypes.any,
  itens: PropTypes.any,
  onClose: PropTypes.any
}

MenuItem.defaultProps = {
  isNested: false,
  isVisible: false
}

export default MenuItem
